'use client';

import AccessDeniedException from '@/exceptions/AccessDeniedException';
import ApiException from '@/exceptions/ApiException';
import UnauthorizedException from '@/exceptions/UnauthorizedException';
import LoggerService from '@/services/LoggerService';
import { IApiAction } from '@/types/api';
import { createContext, useContext } from 'react';

interface AppContextType {
    step: number | null;
    logger: LoggerService;
    setStep: (step: number | null) => void;
    toRoute: (path: string | null, drawerOpen?: boolean) => void;
    switchInlineQuery: (query: string, chatType?: ('users' | 'bots' | 'groups' | 'channels')[]) => void;
    handleApiAction: (apiAction: IApiAction) => void;
    handleApiError: (error: any) => void;
    handleGeneralError: (error: Error) => void;
    handleApiException: (apiException: ApiException) => void;
    showMainButtonProgress: () => void;
    handleUnauthorizedException: (apiException: UnauthorizedException) => void;
    handleAccessDeniedException: (apiException: AccessDeniedException) => void;
};

export const AppContext = createContext<AppContextType | undefined>(undefined);

export const useApp = () => {
    const context = useContext(AppContext);
    if (!context) {
        // logError('useTelegram must be used within a TelegramProvider');
        throw new Error('useApp must be used within a AppProvider');
    }
    return context;
};
