import { IApiAction } from "../types/api";

class ApiException extends Error {
    apiAction: IApiAction;

    constructor(apiAction: IApiAction) {
        super(`API exception occurred.`);
        this.apiAction = apiAction;
    }
};

export default ApiException;
