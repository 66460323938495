'use client';

import { CloseScanQrPopupFunction, ShowScanQrPopupFunction } from '@/hooks/use-scan-qr-popup';
import { IToastAction } from '@/types/api';
import { ColorScheme } from '@/types/enums';
import { IBottomButtonParams, IPopupParams, IThemeParams } from '@/types/telegram';
import { createContext, ReactNode, useContext } from 'react';

interface UiContextType {
    width: number;
    colorScheme: ColorScheme;
    setColorScheme: (colorScheme: ColorScheme) => void;
    setThemeParams: (themeParams: Partial<IThemeParams>) => void;
    showModal: (content: ReactNode) => void;
    hideModal: () => void;
    showDialog: (content: ReactNode) => void;
    hideDialog: () => void;
    showDrawer: (content: ReactNode) => void;
    hideDrawer: () => void;
    showSheet: (content: ReactNode) => void;
    hideSheet: () => void;
    showToast: (toast: IToastAction) => void;
    hideToast: (toastId?: string) => void;
    showLoadingToast: (toastId: string) => void;
    showPopup: (params: IPopupParams) => Promise<string>;
    showMainButton: () => void;
    hideMainButton: () => void;
    setMainButtonParams: (params: IBottomButtonParams) => void;
    disableMainButton: () => void;
    showSecondaryButton: () => void;
    hideSecondaryButton: () => void;
    showBackButton: () => void;
    hideBackButton: () => void;
    showSettingsButton: () => void;
    hideSettingsButton: () => void;
    openMainMenu: () => void;
    closeMainMenu: () => void;
    openSettingsMenu: () => void;
    closeSettingsMenu: (menu?: string, param?: string) => void;
    showSetupMenu: () => void;
    hideSetupMenu: () => void;
    getWidgetIcon: (menu: 'wallet-overview' | 'referral' | 'swap' | 'top-up' | 'withdraw' | 'market' | 'token-staking') => string;
    scanQrPopup: readonly [ShowScanQrPopupFunction, CloseScanQrPopupFunction],
    isMainMenuOpen: boolean;
    isSettingsMenuOpen: boolean;
};

export const UiContext = createContext<UiContextType | undefined>(undefined);

export const useUi = () => {
    const context = useContext(UiContext);
    if (!context) {
        // logError('useTelegram must be used within a TelegramProvider');
        throw new Error('useUi must be used within a UiProvider');
    }
    return context;
};
