import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
import { ApiEndpoints, AppConfig } from "./appConfig";
import ApiException from "@/exceptions/ApiException";
import { IApiResponse, IApiAction } from "@/types/api";
import { Currency, Locale, Network } from "@/types/enums";
import { logError, logInfo } from "./actions";
import numeral from "numeral";
import { ICryptoFees } from "@/types/crypto";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
};

export function getApiUrl(endpoint: string, params?: Record<string, string | number | boolean | null | undefined | Currency | Network> | null): string {
    if (params) {
        const queryString = new URLSearchParams(
            Object.entries(params).reduce((acc, [key, value]) => {
                if (value != null) {
                    acc[key] = String(value);
                }
                return acc;
            }, {} as Record<string, string>)
        ).toString();

        if (queryString) {
            endpoint += `?${queryString}`;
        }
    }

    return `${AppConfig.baseApiUrl}/${endpoint}`;
};

export const handleApiResponse = <T>(apiResponse: IApiResponse<T | IApiAction>): T => {
    if (apiResponse.success) {
        return apiResponse.data as T;
    } else {
        throw new ApiException(apiResponse.data as IApiAction);
    }
};

export function isLocaleSupported(locale: any): boolean {
    return Object.values(Locale).includes(locale);
};

export function isPlatformSupported(platform: string): boolean {
    logInfo('Checking platform ' + platform);
    return true;
};

export const truncateAddress = (address: string, maxLength = 32) => {
    if (address.length <= maxLength) {
        return address;
    }
    const startLength = Math.ceil((maxLength - 3) / 2);
    const endLength = Math.floor((maxLength - 3) / 2);
    return `${address.substring(0, startLength)}...${address.substring(address.length - endLength)}`;
};

export const percentageToFloat = (percentage: number | string): number => {
    if (typeof percentage === 'string') {
        percentage = parseFloat(percentage.replace(/[%\s]/g, ''));
    }

    return percentage;
};

export const formatBalance = (balance: number, currency: string | Currency) => {
    try {
        if (balance == 0) {
            return numeral(balance).format('0,0.0000');
        }

        let formattedBalance;

        if (balance < 0.00001) {
            formattedBalance = numeral(balance).format('0.00000000').replace(/(0*|(\.[0-9]*[1-9]))0+$/, '$1');
        } else if (currency === 'btc') {
            formattedBalance = numeral(balance).format('0.000000').replace(/(0*|(\.[0-9]*[1-9]))0+$/, '$1');
        } else if (currency === 'eth') {
            formattedBalance = numeral(balance).format('0.00000').replace(/(0*|(\.[0-9]*[1-9]))0+$/, '$1');
        } else if (['usdt', 'busd', 'usdc'].includes(currency)) {
            formattedBalance = balance < 0.02 ?
                numeral(balance).format('0,0.0000').replace(/(0*|(\.[0-9]*[1-9]))0+$/, '$1') :
                numeral(balance).format('0,0.00').replace(/(0*|(\.[0-9]*[1-9]))0+$/, '$1');
        } else if (['bnb', 'xmr', 'dot', 'sol', 'ltc', 'txir'].includes(currency)) {
            formattedBalance = numeral(balance).format('0,0.0000').replace(/(0*|(\.[0-9]*[1-9]))0+$/, '$1');
        } else if (currency === 'shib') {
            formattedBalance = numeral(balance).format('0,0.00').replace(/(0*|(\.[0-9]*[1-9]))0+$/, '$1');
        } else {
            formattedBalance = numeral(balance).format('0,0.00').replace(/(0*|(\.[0-9]*[1-9]))0+$/, '$1');
        }

        if (!formattedBalance.includes('.')) {
            return formattedBalance + '.00';
        } else {
            return formattedBalance;
        }
    } catch (error: any) {
        logError('Formatting balance failed.', { currency, balance });
        return balance.toString();
    }
};

export const formatUsdt = (amountInUsdt: number): string => {
    if (amountInUsdt === 0) {
        return '0.00';
    } else if (amountInUsdt < 0.1) {
        return numeral(amountInUsdt).format('0,0.0000');
    } else {
        return numeral(amountInUsdt).format('0,0.00');
    }
};

export const formatPrice = (currencyPrice: number): string => {
    if (currencyPrice === 0) return '0.00';

    let decimalPlaces;

    if (currencyPrice >= 50) {
        decimalPlaces = 2;
    } else if (currencyPrice >= 1) {
        decimalPlaces = 4;
    } else if (currencyPrice >= 0.01) {
        decimalPlaces = 6;
    } else if (currencyPrice >= 0.001) {
        decimalPlaces = 7;
    } else if (currencyPrice >= 0.0001) {
        decimalPlaces = 8;
    } else {
        decimalPlaces = 10;
    }

    const formatted = numeral(currencyPrice).format(`0,0.${'0'.repeat(decimalPlaces)}`).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
    const cleaned = formatted.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
    return cleaned.includes('.') ? cleaned : `${cleaned}.00`;
};

export function sendLog(logMessage: string) {
    fetch(getApiUrl(ApiEndpoints.service.LOGGER), {
        method: 'POST',
        headers: {
            'Content-Type': 'Application/json',
        },
        body: JSON.stringify(logMessage),
    });
};

export const getFormattingOptions = (currency: Currency, amount: number) => {
    let decimals = 4;
    let step = 1;

    if (amount === 0) {
        return { decimals: 2, step: 0 };
    }

    switch (currency) {
        case Currency.Bnb:
        case Currency.Bch:
        case Currency.Sol:
            decimals = 5;
            step = 0.001;
            break;
        case Currency.Btc:
            decimals = 8;
            step = 0.00001;
            break;
        case Currency.Doge:
        case Currency.Dot:
        case Currency.Eth:
            decimals = 6;
            step = 0.0001;
            break;
        case Currency.Ltc:
            decimals = 3;
            step = 0.1;
            break;
        case Currency.Shib:
            decimals = 1;
            step = 0.1;
            break;
        case Currency.Matic:
        case Currency.Trx:
        case Currency.Txir:
        case Currency.Ton:
        case Currency.Usdt:
        case Currency.Usdc:
        case Currency.Xrp:
        case Currency.Ada:
        default:
            decimals = 4;
            if (amount < 10) {
                step = 0.1;
            } else if (amount < 1000) {
                step = 1;
            } else {
                step = 10;
            }
            break;
    }

    return { decimals, step };
};

const txirColor = "#FFFFF";

export function getCurrencyColor(currency: Currency) {
    switch (currency) {
        case Currency.Ada:
            return "#0033AD";
        case Currency.Bch:
            return "#0AC18E";
        case Currency.Bnb:
            return "#E1AB00";
        case Currency.Btc:
            return "#F7931A";
        case Currency.Busd:
            return "#F0B90B";
        case Currency.Doge:
            return "#B79947";
        case Currency.Dot:
            return "#E6007A";
        case Currency.Eth:
            return "#393939";
        case Currency.Matic:
            return "#A229C5";
        case Currency.Shib:
            return "#FF9300";
        case Currency.Sol:
            return "#000008";
        case Currency.Ton:
            return "#0088CC";
        case Currency.Trx:
            return "#FF060A";
        case Currency.Txir:
            return txirColor;
        case Currency.Xmr:
            return "#FF6600";
        case Currency.Xrp:
            return "#262C32";
        case Currency.Ltc:
            return "#345D9D";
        case Currency.Usdt:
            return "#009393";
        case Currency.Usdc:
            return "#2775CA";
        default:
            return "#ECEFF0";
    }
}

export function truncateMiddle(str: string, startChars: number, endChars: number): string {
    if (str.length <= startChars + endChars) {
        return str;
    }

    const start = str.slice(0, startChars); // Начало строки
    const end = str.slice(-endChars); // Конец строки
    return `${start}...${end}`; // Склеиваем с многоточием
}

export function truncateMiddleDynamic(str: string, containerWidth: number, charWidth: number): string {
    const maxChars = Math.floor(containerWidth / charWidth);

    if (str.length <= maxChars) {
        return str;
    }

    const startChars = Math.floor(maxChars / 2) - 2;
    const endChars = Math.floor(maxChars / 2) - 2;

    return `${str.slice(0, startChars)}...${str.slice(-endChars)}`;
}

export function measureTextWidth(text: string, font: string): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
        context.font = font;
        return context.measureText(text).width;
    }
    return 24;
}

export const numFormatter = (num: number): string | number => {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(2) + 'K';
    } else if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else {
        return num;
    }
};

export const commaFormatter = (num: number): string => {
    return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function recalculateLimits(balance: number, cryptoFees: ICryptoFees) {
    const minRequired = Math.max(cryptoFees.fee, cryptoFees.threshold);
    const maxAvailable = balance - cryptoFees.fee;
    if (maxAvailable < 0) {
        return { min: minRequired, max: 0 };
    } else {
        return { min: minRequired, max: maxAvailable };
    }
};