import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            about: "About",
            address: "Address",
            amount_received: "Amount Received",
            amount: "Amount",
            balance: "Balance",
            close: "Close",
            confirm: "Confirm",
            continue: "Continue",
            copied: "Copied to clipboard",
            copy: "Copy",
            current_balance: "Current Balance",
            entry_date: "Entry Date",
            entry_price: "Entry Price",
            exit_date: "Exit Date",
            exit_price: "Exit Price",
            top_up: "Top-Up",
            invalid_address: "Invalid Address",
            invite: "Invite & Earn",
            loading: "Loading",
            market_stats: "Market Stats",
            market: "Market",
            max_available: "Max. Available",
            menu: "Menu",
            token_staking: "Staking TXIR",
            home: "Home",
            min_required: "Min. Required",
            network_fee: "Network Fee",
            network: "Network",
            please_wait: "Please wait",
            portfolio: "Portfolio",
            price: "Price",
            read_more: "Read more",
            referral_link: "Referral Link",
            referral_program: "Referral Program",
            referral_stats: "Referral Stats",
            show_recipients: "Show Recipients",
            recipient: "Recipient",
            swap: "Swap",
            something_went_wrong: "Something went wrong",
            total_balance: "Total Balance",
            total_profit: "Total Profit",
            total_referrals: "Total Referrals",
            total_tokens_bought: "Total Tokens Bought",
            cloud_mining: "Cloud Mining",
            total_tokens_sold: "Total Tokens Sold",
            main_menu: "Main Menu",
            token_buy: "Buy TXIR",
            token_sell: "Sell TXIR",
            transfer: "Transfer",
            available_balance: "Available Balance",
            trade_value: "Trade Value",
            user_id: "User ID",
            wallet: "Wallet",
            welcome: "Welcome",
            withdraw: "Withdraw",
            my_recipients: "My Recipients",
            select_currency: "Select Currency",
            withdrawal: "Withdrawal",
            error: {
                title: 'Error Occurred.',
                desc: 'Please try again later or contact support for assistance'
            },
            referral_details: {
                title: "Referral Details",
                desc: "Copy the referral code and send it to the friend"
            }
        },
    },
    fr: {
        translation: {
            welcome: "Bienvenue",
            portfolio: "Portfolio",
            balance: "Balance",
            market_stats: "Market Stats",
            menu: "Menu",
            close: "Close",
            transfer: "Transfer",
            token_staking: "Staking TXIR",
            address: "Address",
            continue: "Continue",
            wallet: "Кошелек",
            recipient: "Recipient",
            confirm: "Confirm",
            invalid_address: "Invalid Address",
            user_id: "User ID",
            price: "Price",
            total_profit: "Total Profit",
            entry_price: "Entry Price",
            exit_price: "Exit Price",
            entry_date: "Entry Date",
            exit_date: "Exit Date",
            loading: "Loading",
            top_up: "Top-Up",
            swap: "Swap",
            invite: "Invite & Earn",
            total_balance: "Total Balance",
            copied: "Copied to clipboard",
            amount_received: "Amount Received",
            select_currency: "Select Currency",
            show_recipients: "Show Recipients",
            referral_stats: "Referral Stats",
            current_balance: "Current Balance",
            token_sell: "Sell TXIR",

            total_referrals: "Total Referrals",
            something_went_wrong: "Something went wrong...",
            trade_value: "Trade Value",
            network_fee: "Network Fee",
            referral_link: "Referral Link",
            read_more: "Read more",
            amount: "Amount",
            please_wait: "Please wait",
            my_recipients: "My Recipients",
            total_tokens_bought: "Total Tokens Bought",
            cloud_mining: "Cloud Mining",
            total_tokens_sold: "Total Tokens Sold",
            token_buy: "Buy TXIR!",
            about: "About",
            home: "Home",
            network: "Network",
            main_menu: "Main Menu",
            available_balance: "Available Balance",
            copy: "Copy",
            min_required: "Min. Required",
            max_available: "Max. Available",
            referral_program: "Referral Program",
            withdraw: "Withdraw",
            withdrawal: "Withdrawal",
            market: "Market",
            error: {
                title: 'Error Occurred.',
                desc: 'Please try again later or contact support for assistance'
            },
            referral_details: {
                title: "Referral Details",
                desc: "Copy the referral code and send it to the friend"
            },
        },
    },
    ru: {
        translation: {
            about: "About",
            address: "Адрес",
            amount_received: "Полученная сумма",
            amount: "Сумма",
            balance: "Баланс",
            close: "Закрыть",
            confirm: "Подтвердить",
            continue: "Продолжить",
            copied: "Скопировано",
            swap: "Конвертировать",
            copy: "Copy",
            recipient: "Получатель",
            my_recipients: "Мои получатели",
            current_balance: "Актуальный баланс",
            home: "Домой",
            entry_date: "Дата входа",
            entry_price: "Цена входа",
            exit_date: "Дата выхода",
            exit_price: "Цена выхода",
            select_currency: "Выберите валюту",
            invalid_address: "Не верный формат адреса",
            loading: "Загрузка",
            market_stats: "Рынок",
            transfer: "Перевести",
            market: "Market",
            available_balance: "Доступный баланс",
            max_available: "Макс. доступно",
            top_up: "Пополнить",
            invite: "Рефералы",
            token_sell: "Продать TXIR",
            token_buy: "Купить TXIR",
            cloud_mining: "Облачный майнинг",
            menu: "Меню",
            min_required: "Мин. необходимо",
            network_fee: "Комиссия сети",
            network: "Сеть",
            please_wait: "Пожалуйста, подождите",
            portfolio: "Портфель",
            price: "Цена",
            read_more: "Read more",
            token_staking: "Стейкинг TXIR",
            referral_link: "Реферальная ссылка",
            referral_program: "Реферальная программа",
            referral_stats: "Referral Stats",
            main_menu: "Меню",
            show_recipients: "Мои контакты",
            something_went_wrong: "Что-то пошло не так...",
            total_balance: "Общий баланс",
            total_profit: "Общий профит",
            total_referrals: "Всего рефералов",
            total_tokens_bought: "Всего преобретено",
            total_tokens_sold: "Всего продано",
            trade_value: "Размер сделки",
            user_id: "ID пользователя",
            wallet: "Кошелек",
            welcome: "Добро пожаловать",
            withdraw: "Вывести",
            withdrawal: "Вывод",
            error: {
                title: 'Error Occurred.',
                desc: 'Please try again later or contact support for assistance'
            },
            referral_details: {
                title: "Referral Details",
                desc: "Copy the referral code and send it to the friend"
            }
        },
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
