export enum Level {
    Critical = 'critical',
    Error = 'error',
    Warning = 'warning',
    Alert = 'alert',
    Info = 'info',
    Success = 'success',
    Debug = 'debug',
};

export enum Currency {
    Ada = 'ada',
    Bch = 'bch',
    Bnb = 'bnb',
    Btc = 'btc',
    Busd = 'busd',
    Doge = 'doge',
    Dot = 'dot',
    Eth = 'eth',
    Ltc = 'ltc',
    Matic = 'matic',
    Shib = 'shib',
    Sol = 'sol',
    Ton = 'ton',
    Trx = 'trx',
    Txir = 'txir',
    Usdc = 'usdc',
    Usdt = 'usdt',
    Xmr = 'xmr',
    Xrp = 'xrp',
};

export enum Network {
    Ada = 'ada',
    Bch = 'bch',
    Bsc = 'bsc',
    Btc = 'btc',
    Doge = 'doge',
    Dot = 'dot',
    Erc = 'erc',
    Ltc = 'ltc',
    Matic = 'matic',
    Sol = 'sol',
    Trc = 'trc',
    Xmr = 'xmr',
    Xrp = 'xrp',
    Ton = 'ton',
};

export enum Channel {
    TopUp = 'top_up',
    Withdrawal = 'withdrawal',
    Swap = 'swap',
    Transfer = 'transfer',
    MiningPayment = 'mining_contract_payment',
    MiningCashback = 'mining_contract_cashback',
    MiningReward = 'mining_contract_reward',
    ReferralPayout = 'referral_payout',
    ReferralReward = 'referral_reward',
    TokenBuy = 'token_purchase',
    TokenSell = 'token_sale',
    TokensFrozen = 'tokens_frozen',
    TokensUnfrozen = 'tokens_unfrozen',
    TokenStakingReward = 'token_staking_reward',
    TokenAirdrop = 'token_airdrop',
};

export enum ColorScheme {
    Dark = 'dark',
    Light = 'light',
};

export enum Locale {
    Cz = 'cz',
    De = 'de',
    En = 'en',
    Es = 'es',
    Fr = 'fr',
    It = 'it',
    Pl = 'pl',
    Pt = 'pt',
    Ru = 'ru',
    Tr = 'tr',
};
