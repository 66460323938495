import { ChartAreaIcon, ChartCandlestick, CircleCheckBigIcon, CircleCheckIcon, CircleDotDashed, DoorClosedIcon, HourglassIcon, LockIcon, OctagonAlertIcon, OctagonMinusIcon, RocketIcon, TriangleAlertIcon } from "lucide-react";
import { ReactNode } from "react";
import { sendLog } from "./utils";
import { ColorScheme, Network } from "@/types/enums";

export const getIcon = (key: string, size: number = 5, color: string = 'currentColor'): ReactNode => {
    let iconComponent;

    switch (key) {
        case 'mcap':
            iconComponent = <ChartAreaIcon size={size * 4} color={color} />;
            break;
        case 'price':
            iconComponent = <ChartCandlestick size={size * 4} color={color} />;
            break;
        case 'active':
        case 'approved':
        case 'processed':
        case 'successful':
            iconComponent = <CircleCheckIcon size={size * 4} color={color} />
            break;
        case 'cancelled':
            iconComponent = <TriangleAlertIcon size={size * 4} color={color} />
            break;
        case 'closed':
            iconComponent = <LockIcon size={size * 4} color={color} />
            break;
        case 'completed':
        case 'confirmed':
        case 'credited':
            iconComponent = <CircleCheckBigIcon size={size * 4} color={color} />
        case 'declined':
        case 'failed':
        case 'finished':
            break;
        case 'suspended':
        case 'pending':
            iconComponent = <HourglassIcon size={size * 4} color={color} />;
            break;
        case 'rejected':
            iconComponent = <OctagonMinusIcon size={size * 4} color={color} />;
            break;
        default:
            sendLog(`iconHelper: unsupported condition: ${key}`);
            iconComponent = <RocketIcon size={size * 4} color={color} />;
            break;
    }

    return iconComponent;
};

export function getWidgetMenuIcon(colorScheme: ColorScheme, menu: 'wallet-overview' | 'referral' | 'top-up' | 'swap' | 'withdraw' | 'market' | 'token-staking') {
    switch (menu) {
        case 'wallet-overview':
            if (colorScheme === ColorScheme.Light) {
                return '/assets/icons/icon-wallet-light.svg';
            } else {
                return '/assets/icons/icon-wallet-dark.svg';
            }
        case 'referral':
            if (colorScheme === ColorScheme.Light) {
                return '/assets/icons/icon-invite-light.svg';
            } else {
                return '/assets/icons/icon-invite-dark.svg';
            }
        case 'top-up':
            if (colorScheme === ColorScheme.Light) {
                return '/assets/icons/icon-deposit-light.svg';
            } else {
                return '/assets/icons/icon-deposit-dark.svg';
            }
        case 'withdraw':
            if (colorScheme === ColorScheme.Light) {
                return '/assets/icons/icon-wallet-light.svg';
            } else {
                return '/assets/icons/icon-wallet-dark.svg';
            }
        case 'market':
            if (colorScheme === ColorScheme.Light) {
                return '/assets/icons/';
            } else {
                return '/assets/icons/';
            }
        case 'token-staking':
            if (colorScheme === ColorScheme.Light) {
                return '/assets/icons/icon-stacking-light.svg';
            } else {
                return '/assets/icons/icon-stacking-dark.svg';
            }
        case 'swap':
            if (colorScheme === ColorScheme.Light) {
                return '/assets/icons/icon-exchange-light.svg';
            } else {
                return '/assets/icons/icon-exchange-dark.svg';
            }
    }
}