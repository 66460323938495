
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AppState = {
    isLoading: boolean;
    isMenuOpen: boolean;
    isModalOpen: boolean;
    isDialogOpen: boolean;
    isDrawerOpen: boolean;
    isSettingsOpen: boolean;
    isPopupOpen: boolean;
    isSheetOpen: boolean;
    isLoadingFees: boolean;
    isValidating: boolean;
    isConfirmed: boolean;
};

const initialState: AppState = {
    isLoading: false,
    isMenuOpen: false,
    isModalOpen: false,
    isDialogOpen: false,
    isDrawerOpen: false,
    isSettingsOpen: false,
    isPopupOpen: false,
    isSheetOpen: false,
    isLoadingFees: false,
    isValidating: false,
    isConfirmed: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setIsLoading: (state: AppState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setMenuOpen: (state: AppState, action: PayloadAction<boolean>) => {
            state.isMenuOpen = action.payload;
        },
        setModalOpen: (state: AppState, action: PayloadAction<boolean>) => {
            state.isModalOpen = action.payload;
        },
        setDialogOpen: (state: AppState, action: PayloadAction<boolean>) => {
            state.isDialogOpen = action.payload;
        },
        setSettingsOpen: (state: AppState, action: PayloadAction<boolean>) => {
            state.isSettingsOpen = action.payload;
        },
        setDrawerOpen: (state: AppState, action: PayloadAction<boolean>) => {
            state.isDrawerOpen = action.payload;
        },
        setSheetOpen: (state: AppState, action: PayloadAction<boolean>) => {
            state.isSheetOpen = action.payload;
        },
        setIsLoadingFees: (state: AppState, action: PayloadAction<boolean>) => {
            state.isLoadingFees = action.payload;
        },
        setIsValidating: (state: AppState, action: PayloadAction<boolean>) => {
            state.isValidating = action.payload;
        },
        setIsConfirmed: (state: AppState, action: PayloadAction<boolean>) => {
            state.isConfirmed = action.payload;
        }
    },
});

export const {
    setIsLoading,
    setMenuOpen,
    setModalOpen,
    setDialogOpen,
    setSettingsOpen,
    setDrawerOpen,
    setSheetOpen,
    setIsLoadingFees,
    setIsValidating,
    setIsConfirmed
} = appSlice.actions;

export default appSlice.reducer;
