export const AppConfig = {
    baseApiUrl: 'https://tgcache.link/api/web-app',
    params: {
        CRYPTO_CURRENCIES_UPDATE_INTERVAL: 120000,
        TOTAL_BALANCE_UPDATE_INTERVAL: 120000,
    },
};

export const ApiEndpoints = {
    auth: {
        AUTH_USER: 'auth/authorize-user',
    },
    userProfile: {
        GET_TIME_OFFSET: 'user/profile/get-time-offset',
        SWITCH_LANGUAGE: 'user/profile/switch-language',
        GET_SEED_PHRASE: 'user/profile/get-seed-phrase',
        GET_REFERRAL_URL: 'user/profile/get-referral-url',
    },
    wallet: {
        GET_CRYPTO_CURRENCY: 'user/wallet/get-crypto-currency',
        GET_CRYPTO_CURRENCIES: 'user/wallet/get-crypto-currencies',
        GET_TOTAL_BALANCE: 'user/wallet/get-total-balance',
        GET_TOTAL_PROFIT: 'user/wallet/get-total-profit',
        GET_WALLET_STATS: 'user/wallet/get-wallet-stats',

        GET_TRANSACTIONS: 'user/wallet/get-transactions',
        GET_TOTAL_TRANSACTIONS: 'user/wallet/get-total-transactions',
        GET_TRANSACTION_DETAILS: 'user/wallet/get-transaction-details',

        GET_BOT_USER_TOKEN_STATS: 'user/wallet/get-bot-user-token-portfolio-stats',

        GET_TOKEN_STAKINGS: 'user/wallet/get-token-stakings',
        GET_TOKEN_STAKING_STATS: 'user/wallet/get-token-staking-stats',
        GET_TOKEN_STAKING_DETAILS: 'user/wallet/get-token-staking-details',

        GET_LINKED_TOP_UP_ADDRESS: 'user/wallet/get-linked-top-up-address',
        GET_WITHDRAWAL_ADDRESSES: 'user/wallet/get-withdrawal-addresses',
        GET_TRANSFER_RECIPIENTS: 'user/wallet/get-transfer-recipients',

        GET_REFERRAL_STATS: 'user/wallet/get-referral-stats',
        GET_REFERRAL_URL: 'user/wallet/get-referral-url',

        COMMIT_TRANSACTION: 'user/wallet/commit-transaction',
    },
    crypto: {
        GET_CRYPTO_CURRENCY_PRICE: 'crypto/crypto-currency/get-price',
        GET_CRYPTO_CURRENCY_CHART_DATA: 'crypto/crypto-currency/get-chart-data',
        GET_CRYPTO_CURRENCY_MARKET_DATA: 'crypto/crypto-currency/get-market-data',
        GET_CRYPTO_CURRENCY_INFO: 'crypto/crypto-currency/get-info',
        GET_TRENDING_CRYPTO_CURRENCIES: 'crypto/get-trending-crypto-currencies',
        GET_TOP_MCAP_CRYPTO_CURRENCIES: 'crypto/get-top-mcap-crypto-currencies',
        GET_CRYPTO_NETWORKS: 'crypto/networks/get',
        GET_CRYPTO_FEES: 'crypto/get-fees',
        GET_TOP_UP_ADDRESS: 'crypto/get-top-up-address',
        GET_TOKEN_STAKING_TERMS: 'crypto/token/get-token-staking-terms',
        VALIDATE_ADDRESS: 'crypto/validate-address',
    },
    service: {
        LOGGER: 'service/logger',
        GET_TRANSFER_RECIPIENT: 'service/get-transfer-recipient',
        VALIDATE_USER_ID: 'service/validate-user-id',
    },
}