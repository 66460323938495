import Fade from '@mui/material/Fade';
import styles from './style.module.scss';
import Image from 'next/image';

const Loader: React.FC<{ visible: boolean }> = ({ visible }) => {
    return (
        <div className="app">
            <div className="app_container">
                <Fade in={visible} timeout={400}>
                    <div className={styles.container}>
                        <Image
                            src="/assets/loader.svg"
                            alt="loader"
                            className={styles.animation}
                            height={200}
                            width={200}
                        />
                    </div>
                </Fade>
            </div>
        </div>
    );
}

export default Loader;